import React from "react"
import styled from "styled-components"
import Footer from "../../components/footer"
import { Helmet } from "react-helmet"

import { Row, Col } from "antd"

import { FaWhatsapp } from "react-icons/fa"

export default class FrontEnd extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Vaga para Desenvolvedor Front-End</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Se você tem vocação para desenvolver interfaces intuitivas e fluidas e tem experiência com ReactJS, envie seu currículo!"
          />
        </Helmet>

        <Header>
          <Row style={{ height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-start",
                paddingLeft: "24px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Righteous",
                  fontSize: "1.8em",
                  width: "64px",
                  margin: "0px",
                }}
              >
                deli
              </p>
            </Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>

            <Col xs={0} sm={0} md={0} lg={4} xl={4}></Col>
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={4}
              xl={4}
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "24px",
              }}
            >
              <FaWhatsapp size="1.78em" />
              <span
                style={{
                  fontFamily: "IBM Plex Sans",
                  color: "#3f6aea",
                  fontSize: "1.2em",
                  marginLeft: "6px",
                }}
              >
                {"(41) 9 9757-4479"}
              </span>
            </Col>
          </Row>
        </Header>
        <TextSection>
          <Center>
            <Valign>
              <TextContainer>
                <h3
                  style={{ fontFamily: "IBM Plex Sans", marginBottom: "24px" }}
                >
                  VAGA PARA DESENVOLVEDOR FRONT-END EM CURITIBA
                </h3>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                  }}
                >
                  Sobre nós:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Você provavelmente já deve ter reparado que existem muitos
                  sistemas antigos operando nos balcões de restaurantes, cafés e
                  similares. Nós escolhemos atender esse segmento de uma maneira
                  totalmente diferente. A{" "}
                  <span style={{ fontFamily: "Righteous", color: "#3f6aea" }}>
                    deli
                  </span>{" "}
                  desenvolve soluções usando a vanguarda da ciência de dados e
                  da engenharia de software. Estamos revolucionando o mercado de
                  sistemas de ponto de venda porque trazemos experiências de
                  usuário diferenciadas graças às nossas ferramentas de
                  visualização e interfaces rápidas.
                </p>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Sobre esta vaga:
                </h4>
                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Procuramos novos membros capazes de construir e melhorar o
                  client-side de nossas aplicações web usando as melhores
                  técnicas de Javascript HTML e CSS. Contudo, trabalhar
                  diretamente com design não faz parte do escopo dessa vaga.
                </p>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Responsabilidades:
                </h4>
                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Transformar os protótipos da equipe de design em código
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Desenvolver e manter páginas totalmente responsivas usando
                    ReactJS
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Escrever testes automatizados
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Auxiliar a equipe de QA a encontrar e resolver bugs nas
                    interfaces
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Estudar novos frameworks e tecnologias assegurando a
                    viabilidade técnica das ferramentas escolhidas
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Otimizar aplicações buscando melhor aproveitamento de
                    recursos e experiências mais rápidas.
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Colaborar com nossas equipes e stakeholders
                  </li>
                </ul>

                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Aquilo que procuramos:
                </h4>

                <ul>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    2 ou + anos de experiência com HTML, CSS e Javascript
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Conhecimento de GIT
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Familiaridade com SASS, LESS, Styled Components ou similar
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Participação em projetos Open Source
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    {" "}
                    Um portfolio autêntico que mostra suas habilidades mais
                    fortes
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Experiência com alguma outra linguagem como Rust ou Elixir
                  </li>
                  <li
                    style={{
                      textAlign: "left",
                      fontSize: "1.2em",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Inglês fluente
                  </li>
                </ul>
                <h4
                  style={{
                    fontFamily: "IBM Plex Sans",
                    textAlign: "left",
                    marginTop: "24px",
                  }}
                >
                  Tem interesse?
                </h4>

                <p
                  style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "1.2em",
                    color: "#222222",
                    textAlign: "left",
                  }}
                >
                  Se você se interessou, envie seu currículo e portfolio para
                  <b style={{ color: "#3f6aea" }}> vagas@delipos.com.br</b>
                </p>
              </TextContainer>
            </Valign>
          </Center>
        </TextSection>
        <Footer />
      </div>
    )
  }
}

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  color: #3f6aea;
  height: 48px;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

const TextSection = styled.div`
  padding-top: 48px;
  width: 100%;
  min-height: 95vh;
  background: white;
`

const TextContainer = styled.div`
  display: inline-block;
  @media (min-width: 992px) {
    width: 600px;
  }

  @media (max-width: 993px) {
    width: 90%;
  }
`

const Center = styled.div`
  display: table;
  width: 100%;
  height: auto;
`
const Valign = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`
